
import { Options, Vue } from 'vue-class-component'
import moment from "moment";

@Options({
    props: {
        endtime: Number
    }
})
export default class Counter extends Vue {
    endtime!: number
    time: moment.Duration | null = null

    get humanizations () {
        if (!this.time) return null

        return [
            this.plural(this.time.days(), ["день", "дня", "дней"]),
            undefined,
            this.plural(this.time.hours(), ["час", "часа", "часов"]),
            undefined,
            this.plural(this.time.minutes(), ["минуту", "минуты", "минут"]),
            undefined,
            this.plural(this.time.seconds(), ["секунду", "секунды", "секунд"])
        ]
    }

    mounted () {
        this.time = moment.duration(moment.unix(this.endtime).diff(moment()))

        setInterval(() => {
            this.time = moment.duration(moment.unix(this.endtime).diff(moment()))
        }, 1000)
    }

    plural (number: number, value: string[]): string {
        const plural10Number = number % 10
        const plural100Number = number % 100

        if ((plural100Number >= 10 && plural100Number <= 20)
            || plural10Number >= 5
            || plural10Number == 0) return value[2]

        if (plural10Number >= 2) return value[1]

        return value[0]
    }
}
