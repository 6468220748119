
import { Options, Vue } from 'vue-class-component';
import Counter from "@/components/Counter.vue";
import { END_TIME } from "@/config";

@Options({
    components: {
        Counter
    },
})
export default class App extends Vue {
    endtime = END_TIME
    email = ""
    isMobile = window.innerWidth <= 610
    isSubscribed = localStorage["subscribed"] == true

    declare $refs: {
        emailElement: HTMLInputElement,
        captchaElement: HTMLDivElement
    }

    mounted () {
        window.addEventListener("resize", () => {
            this.isMobile = window.innerWidth <= 610
        })

        window.addEventListener("onload", () => {
            document
                .querySelector('#g-recaptcha-response')
                ?.setAttribute("required", "required");
        })
    }

    async submitForm () {
        if (this.isSubscribed) return

        try {
            if ((window as any).grecaptcha.getResponse().length == 0) {
                alert("Пройдите капчу")
                return
            }
        } catch (e) {
            // Do nothing
        }

        this.isSubscribed = true
        localStorage["subscribed"] = this.isSubscribed

        await fetch("https://api.actamall.ru/subscribe", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: this.$refs.emailElement.value
            })
        })
    }

    open (url: string) {
        window.open(url)
    }
}
